import { ISODateTime, UUID } from "api/types";
import { AttributeCategory, BoardFormat } from "../schemas/models";
import { UserWithInitials } from "api/users/models";
import { Assign } from "utility-types";
import { ManufacturingMaterialKind, PostOrderManufacturingMaterial } from "../materials/models";

export enum ManufacturingUnitGroupPriority {
  A = "A",
  B = "B",
  C = "C",
}

export enum ManufacturingUnitGroupType {
  GROUP = "GROUP",
  MANUFACTURING_WORKING_UNIT = "MANUFACTURING_WORKING_UNIT",
}

export enum ImplementedBy {
  CONTRACTOR = "CONTRACTOR",
  IN_HOUSE = "IN_HOUSE",
}

export enum UnitStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
}

export enum MaterialStageStatus {
  AT_CONTRACTOR = "AT_CONTRACTOR",
  NOT_ORDERED = "NOT_ORDERED",
  ORDERED = "ORDERED",
  RECEIVED = "RECEIVED",
}

export enum StageStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  READY = "READY",
  FINISHED = "FINISHED",
}

export interface Attribute {
  attribute: {
    id: number | null;
    name: string;
  };
  category: AttributeCategory;
  value: {
    id: number | null;
    name: string;
  };
}

export interface TicketElementsCount {
  A: number;
  B: number;
  C: number;
  D: number;
  E: number;
}

export interface ManufacturingUnitGroup {
  attributes: Attribute[];
  createdAt: ISODateTime;
  elementsCount: TicketElementsCount;
  employee: UserWithInitials | null;
  group: {
    id: UUID;
    signature: string;
  } | null;
  id: UUID;
  implementedBy: ImplementedBy | null;
  isDeclined: boolean;
  manufacturer: {
    id: number;
    name: string;
  } | null;
  manufacturingItems: {
    id: UUID;
  }[];
  priority: ManufacturingUnitGroupPriority | "";
  scheduledAt: ISODateTime | null;
  signature: string;
  startedAt: ISODateTime;
  type: ManufacturingUnitGroupType;
}

export interface ManufacturingUnitGroupDetails {
  attributes: Attribute[];
  createdAt: ISODateTime;
  employee: UserWithInitials | null;
  manufacturingItem: {
    id: UUID;
  };
  group: {
    id: number;
    signature: string;
  } | null;
  id: UUID;
  isDeclined: boolean;
  manufacturingItems: {
    id: UUID;
  }[];
  note: string;
  order: {
    id: number;
    signature: string;
  } | null;
  priority: ManufacturingUnitGroupPriority;
  scheduledAt: ISODateTime;
  signature: string;
  type: ManufacturingUnitGroupType;
}

export interface ManufacturingUnit {
  attributeValues: Attribute[];
  createdAt: ISODateTime;
  employee: UserWithInitials | null;
  finishedAt: ISODateTime;
  id: UUID;
  implementedBy: ImplementedBy | null;
  isDeclined: boolean;
  isOutdated: boolean;
  manufacturer: {
    id: number;
    name: string;
  } | null;
  manufacturingItem: UUID;
  manufacturingItemIdentity: string;
  name: string;
  note: string;
  orderSignature: string;
  priority: ManufacturingUnitGroupPriority;
  priorityOrder: null;
  scheduledAt: ISODateTime | null;
  signature: string;
  startedAt: ISODateTime;
  status: "NOT_STARTED" | "READY";
}

export interface ManufacturingGroup {
  attributesValues: Attribute[];
  elements: {
    attributeValues: {
      attribute: {
        id: string;
        name: string;
      };
      kind: string;
      value: {
        id: string;
        name: string;
      };
    }[];
    id: UUID;
    isDeclined: boolean;
    name: string;
    orderSignature: string;
    priority: ManufacturingUnitGroupPriority;
    signature: string;
    manufacturingItemId: UUID;
  }[];
  modelName: string;
  names: string[];
}

export interface ManufacturingGroupWithId extends ManufacturingGroup {
  id: UUID;
}

export interface GroupManufacturingItemPayload {
  manufacturingWorkingUnitIds: UUID[];
  schema_stage_id: UUID;
  attributes: {
    attribute: {
      id: number | null;
      name: string | null;
    };
    category: string | null;
    value: {
      id: number | null;
      name: string | null;
    };
  }[];
}

export interface GroupedUnitInProgress {
  attributes: Attribute[];
  id: UUID;
  isDeclined: boolean;
  note: string;
  order: {
    id: number;
    signature: string;
  } | null;
  priority: ManufacturingUnitGroupPriority;
  signature: string;
}

export interface SetManufacturingUnitsAsFinished {
  manufacturingWorkingUnitsIds: UUID[];
  manufacturingWorkingUnitGroupsIds: UUID[];
}

export interface ManufacturingItemCount {
  counts: {
    finished: number;
    inProgress: number;
    notStarted: number;
  };
  schemaStage: {
    boardFormat: BoardFormat;
    id: UUID;
    name: string;
  };
}

export interface ManufacturingPlanBaseDetails {
  id: UUID;
  name: string;
  signature: string;
}

export interface NextOrPreviousStage {
  code: string;
  color: string;
  id: UUID;
  name: string;
  position: number;
  status: StageStatus;
}

export interface ManufacturingUnitListViewItem {
  employee: UserWithInitials | null;
  fabrics: {
    id: number;
    name: string;
  }[];
  id: UUID;
  implementedBy: ImplementedBy;
  location: string;
  manufacturer: {
    id: number;
    name: string;
  } | null;
  manufacturingItem: {
    countryCode: string;
    id: UUID;
    name: string;
    orderId: number;
    signature: string;
  };
  manufacturingSchema: ManufacturingPlanBaseDetails | null;
  materialOrders: {
    createdAt: ISODateTime;
    id: UUID;
    material: {
      category: AttributeCategory;
      externalId: string;
      id: UUID;
      kind: ManufacturingMaterialKind;
      unit: string;
    };
    status: MaterialStageStatus;
  }[];
  materials: {
    averageDeliveryDays: number | null;
    id: UUID;
    isCustomOrdered: boolean;
    manufacturers: {
      id: number;
      name: string;
    }[];
    name: string;
    orders: {
      id: UUID;
      status: MaterialStageStatus;
    }[];
    quantity: number;
    unit: {
      id: UUID;
      shortName: string;
    };
  }[];
  nextStages: NextOrPreviousStage[];
  previousStages: NextOrPreviousStage[];
  priority: ManufacturingUnitGroupPriority;
  scheduledAt: ISODateTime | null;
  startedAt: ISODateTime | null;
  status: UnitStatus;
}

export interface ManufacturingUnitListViewDetails extends ManufacturingUnitListViewItem {
  createdAt: ISODateTime;
  index: {
    id: number;
    name: string;
  } | null;
  note: string;
  manufacturingWorkingUnitGroup: {
    id: UUID;
    signature: string;
  } | null;
}

export interface BulkUpdateManufacturingUnitsListPayload {
  employeeId: number;
}

export interface CustomOrderedMaterial {
  isAlreadyOrdered: boolean;
  manufacturingItem: {
    id: UUID;
    signature: string;
  };
  manufacturingWorkingUnit: {
    id: UUID;
    signature: string;
  };
  material: {
    averageDeliveryDays: number;
    id: UUID;
    isCustomOrdered: boolean;
    manufacturers: {
      id: number;
      isMain: boolean;
      name: string;
    }[];
    name: string;
    quantity: number;
    unit: {
      id: UUID;
      shortName: string;
    };
  };
}

export type CustomOrderedMaterialColumn = Assign<
  PostOrderManufacturingMaterial["orders"][number],
  {
    manufacturingWorkingUnit: UUID;
    manufacturingWorkingUnitSignature: string;
    manufacturers: {
      id: number;
      isMain: boolean;
      name: string;
    }[];
    unit: {
      id: UUID;
      shortName: string;
    };
  }
>;
